import { getRequest, postRequest, deleteRequest } from './index'

// OA审批的分页
export const auditInfoQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/oa/auditInfo/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// OA审批的分页  审核接口
export const auditInfoAudit = (data, successCallback, failureCallback) => {
  postRequest('/oa/auditInfo/audit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取打印结算单的详情
export const agentSettlePrintSettleDocData = (id, successCallback, failureCallback) => {
  getRequest(`/agentSettle/printSettleDocData/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取垫付记录
export const agentSettlePayAdvanceDetailSettle = (contractId, successCallback, failureCallback) => {
  getRequest(`/agentSettle/payAdvanceDetailSettle/${contractId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 列表接口分页
export const agentSettleInfoQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/agentSettle/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 结算详情
export const agentSettleInfoQueryByDetail = (id, successCallback, failureCallback) => {
  getRequest(`/agentSettle/queryByDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// OA审批的分页  审核接口
export const auditInfoQueryBydetail = (data, successCallback, failureCallback) => {
  postRequest('/oa/auditInfo/queryBydetail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 开票列表接口分页  /invoiceIssure/invoiceDetail
export const invoiceIssureQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/invoiceIssure/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合作企业详情分页
export const agentSupplierList = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplier/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取合作企业的详情
export const agentSupplierDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentSupplier/details?id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合作企业详情添加和修改
export const agentSupplierAddAndUpdate = (data, successCallback, failureCallback) => {
  postRequest(data.id ? '/agentSupplier/update' : '/agentSupplier/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合作企业删除
export const agentSupplierDelete = (id, successCallback, failureCallback) => {
  deleteRequest(`/agentSupplier/delete/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 合作企业详情删除
// export const agentSupplierDelete = (data, successCallback, failureCallback) => {
//   postRequest('/agent/supplier/delete', data, res => (
//     successCallback(res)
//   ), error => {
//     if (failureCallback)failureCallback(error)
//   })
// }

// 合作企业详情终止/合作
export const agentSupplierTerminationOrCooperation = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplier/operateUpdate', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 合作企业详情修改
export const agentSupplierUpdate = (data, successCallback, failureCallback) => {
  postRequest('/agent/supplier/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户分页
export const agentCustomerQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户添加
export const agentCustomerAdd = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户修改
export const agentCustomerUpdate = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户详情
export const agentCustomerDetail = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户终止/启用
export const agentCustomerEnableOrDisanle = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/enableOrDisanle', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户下拉列表(客户状态仅为[1正常]的数据)
export const agentCustomerQueryByList2AccessStateIs1 = (data, successCallback, failureCallback) => {
  getRequest('/agent/customer/queryByList2AccessStateIs1', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户下拉列表(客户状态为所有[0终止，1正常]的数据)
export const agentCustomerQueryByList2AllAccessState = (data, successCallback, failureCallback) => {
  getRequest('/agent/customer/queryByList2AllAccessState', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 授信管理
// 授信管理分页  1-经销商端，2-垫资端，3-运营端
export const agentCreditInfoList = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/listByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取经销商端的详情
export const agentFinancialInfoDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/details?operFlat=3&id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取代采端的详情
export const agentCreditInfoDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/details?id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 模糊搜索经销商
export const agentFinancialInfoSearch = (data, successCallback, failureCallback) => {
  postRequest('/agentFinancialInfo/search', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业财务信息管理
export const agentFinancialInfoList = (data, successCallback, failureCallback) => {
  postRequest('/agentFinancialInfo/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加存量客户
export const agentCreditInfoInsertStockData = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加审核包括风控和业务员
export const agentCreditInfoOperateUpdate = (data, successCallback, failureCallback) => {
  postRequest(data.currentStatus === 1 ? '/agentCreditInfo/busiAudit' : '/agentCreditInfo/riskAudit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度调整
export const agentCreditInfoAdjustmentQuota = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/adjustmentQuota', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信签署（只传授信额度对象id
export const agentCreditInfoCustomersSign = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/getEsignUrl?creditId=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信签署 用印申请
export const agentCreditInfoSealApply = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/sealApply?creditId=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货流水分页
export const deliveryInfoPage = (data, successCallback, failureCallback) => {
  postRequest('/deliveryInfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货流水详情
export const deliveryInfoDetail = (id, successCallback, failureCallback) => {
  getRequest(`deliveryInfo/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货确认签署
export const deliveryInfoGetEsignUrl = (id, successCallback, failureCallback) => {
  postRequest(`deliveryInfo/getEsignUrl/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同分页
export const agentPurchaseContractFundsPage = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/fundsPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 采购合同管理详情
export const agentPurchaseContractFundsDetail = (id, successCallback, failureCallback) => {
  getRequest(`/agentPurchaseContract/fundsDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 销售合同分页
export const agentPurchaseContractPage = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 保付入金回调
export const abcBailPayAddAmountNotify = (data, successCallback, failureCallback) => {
  postRequest('/abc/bailPay/addAmountNotify', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 代采端垫资付款
export const agentPurchaseContractUpdateStatus = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchaseContract/updateStatus', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同管理详情
export const agentPurchaseContractDetail = (id, successCallback, failureCallback) => {
  getRequest(`/agentPurchaseContract/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同管理详情
export const agentPurchaseContractSynPurContract = (data, successCallback, failureCallback) => {
  postRequest('agentPurchaseContract/synPurContract', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 代采端关联销售合同编号查询代采端销售合同详情
export const agentPurchaseContractFundsAndCustDetail = (id, successCallback, failureCallback) => {
  getRequest(`agentPurchaseContract/fundsAndCustDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取销售合同的签署链接
export const agentPurchaseContractGetEsignUrl = (id, successCallback, failureCallback) => {
  postRequest(`agentPurchaseContract/getEsignUrl/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购计划分页查询
export const agentPurchasePlanPage = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购计划详情查询
export const agentPurchasePlanDetail = (data, successCallback, failureCallback) => {
  postRequest('/agentPurchasePlan/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 根据钢厂合同编号查出信息
export const agentPurchaseContractExWarehouseInfo = (data, successCallback, failureCallback) => {
  postRequest(`/agentPurchaseContract/exWarehouseInfo`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

